import routes from "@utils/routes"

export const menuLabel = {
  en: {
    [routes.home]: "Home",
    [routes.blog]: "Blog",
    [routes.vagas]: "Jobs",
    [routes.imprensa]: "Media",
    [routes.portfolio]: "Portfolio",
    [routes.servicos]: "Services",
  },
  "pt-BR": {
    [routes.home]: "Home",
    [routes.blog]: "Blog",
    [routes.vagas]: "Vagas",
    [routes.imprensa]: "Imprensa",
    [routes.portfolio]: "Portfólio",
    [routes.servicos]: "Serviços",
  },
}[process.env.GATSBY_LANGUAGE]
