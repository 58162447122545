import React from "react"
import { Link } from "rebass"
import * as Style from "./SocialMediaItem.styles"

export function SocialMediaItem({ icon, link }) {
  return (
    <Style.ListItem>
      <Link href={link} target="__blank">
        <Style.IconWrapper>{icon}</Style.IconWrapper>
      </Link>
    </Style.ListItem>
  )
}
