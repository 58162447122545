import routes from "@utils/routes"

const hiddenMenuItensByLanguage = {
  en: [routes.vagas],
  "pt-BR": [],
}

export const menuItems = [
  routes.home,
  routes.portfolio,
  routes.servicos,
  routes.imprensa,
  routes.blog,
  routes.vagas,
].filter(
  route =>
    !hiddenMenuItensByLanguage[process.env.GATSBY_LANGUAGE].includes(route)
)

export const homeContent = {
  card: [
    {
      image: "agileProjects",
      title: { as: "h4", label: "cards.agileProjects" },
      subtitle: { as: "p", label: "cards.agileProjectsDescription" },
    },
    {
      image: "devops",
      title: { as: "h4", label: "cards.devops" },
      subtitle: { as: "p", label: "cards.devopsDescription" },
    },
    {
      image: "developerOnDemand",
      title: { as: "h4", label: "cards.developerOnDemand" },
      subtitle: {
        as: "p",
        label: "cards.developerOnDemandDescription",
      },
    },
  ],
  cases: {
    title: { as: "h4", label: "home.develop.developFor" },
    clients: [
      {
        company: "Suzano Papel e Celulose",
        employee: "Mario Del Matto",
        text:
          "Temos uma excelente parceria com a X-Apps no desenvolvimento de projetos, agora com três sistemas na área de biotecnologia e dois para demandas internas.",
      },
      {
        company: "Engenheiro BMW Motorrad",
        employee: "Sérgio Fossa",
        text:
          "A parceria com a X-Apps proporcionou a interação da moto com o celular, como também espelhar as rotas para andar em grupo.",
      },
    ],
  },
}
