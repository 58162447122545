import Translate from "@components/Translate"
import { menuItems } from "@constants/home"
import routes from "@utils/routes"
import { Botao } from "@xapps/design-system"
import classNames from "classnames"
import { navigate } from "gatsby"
import { Link } from "gatsby-plugin-intl"
import React from "react"
import * as Style from "./Menu.styles"
import { menuLabel } from "./menuLabel"
import { IdsDosBotoesDeConversaoDoLeadsCenter } from "@constants/forms"

function Menu({ show, underMenuItem }) {

  const goToContact = () =>
    navigate(
      `${routes.contato}?form_id=${IdsDosBotoesDeConversaoDoLeadsCenter.menu}`
    )

  const goToRequestSquads = () => navigate(routes.contrateSquads)
  // const goToRequestSquads = () => {
  //   window.location.href = "https://perfil.x-apps.com.br/"
  // }

  return (
    <Style.Wrapper
      className={classNames({
        show,
      })}
    >
      <Style.MenuItemsWrapper>
        {menuItems.map(
          (item, index) =>
            index !== 0 && (
              <Style.MenuItem key={index}>
                <Link
                  activeClassName="active"
                  className={classNames({
                    active: underMenuItem === item,
                  })}
                  partiallyActive
                  to={item}
                >
                  {menuLabel[item]}
                </Link>
              </Style.MenuItem>
            )
        )}
        <Style.MenuItem className="hidden-in-desktop">
          {process.env.GATSBY_LANGUAGE !== "en" && (
            <Botao
              sx={{ width: ["100%", "auto"] }}
              variant={
                process.env.GATSBY_LANGUAGE === "pt-BR"
                  ? "terciario"
                  : "primario"
              }
              onClick={goToRequestSquads}
            >
              <Translate id="header.buttons.hireSquad" />
            </Botao>
          )}
        </Style.MenuItem>
        <Style.MenuItem className="hidden-in-desktop">
          <Botao
            variant={
              process.env.GATSBY_LANGUAGE === "pt-BR" ? "primario" : "terciario"
            }
            sx={{ width: ["100%", "auto"] }}
            onClick={goToContact}
          >
            <Translate id="header.buttons.requestBudget" />
          </Botao>
        </Style.MenuItem>
      </Style.MenuItemsWrapper>
    </Style.Wrapper>
  )
}

export default Menu
