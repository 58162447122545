/** @jsx jsx */
import Content, { WidthLimitation } from "@components/Content"
import { Link as GatsbyLink } from "gatsby-plugin-intl"
import { Box, Flex, Heading, Link, Text } from "rebass"
import { jsx } from "theme-ui"

const linkStyle = {
  lineHeight: 1.5625,
  display: "block",
  fontWeight: "light",
  textDecoration: "none",
  color: "inherit",
  "&:hover": {
    opacity: 0.4,
  },
}

export function Wrapper({ children }) {
  return (
    <Box
      bg="blueDark"
      color="white"
      fontFamily="body"
      justifyContent="center"
      paddingX={3}
      paddingTop={5}
      paddingBottom="96px"
      width="100%"
      sx={{
        bottom: 0,
        margin: "0 auto",
      }}
    >
      <WidthLimitation flexDirection="column" margin="0 auto">
        {children}
      </WidthLimitation>
    </Box>
  )
}
export function ColumnWrapper({ children, hasBorder }) {
  return (
    <Flex
      color="white"
      fontFamily="body"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
      mt={hasBorder ? 4 : 0}
      pt={hasBorder ? 4 : 0}
      className="aqioooo"
      sx={{
        bottom: 0,
        borderTop: hasBorder ? "1px solid #243e76" : "none",
        width: "100%",
      }}
    >
      {children}
    </Flex>
  )
}

export function MessageWrapper({ children, ...props }) {
  return (
    <Flex
      height="100%"
      justifyContent="center"
      py="10px"
      textAlign="center"
      sx={{
        backgroundColor: "blueMedium",
        bottom: 0,
        color: "white",
        ...props,
      }}
    >
      <Content flexDirection="column">{children}</Content>
    </Flex>
  )
}

export function VerticalDivider() {
  return (
    <Box
      sx={{
        width: ["100%", "100%", "1px"],
        height: ["1px", "1px", "unset"],
        my: ["32px", "32px", "10px"],
        backgroundColor: "blue",
        mx: [0, 0, "60px"],
      }}
    />
  )
}

export const SocialMediaTitle = props => (
  <Text
    sx={{
      display: "flex",
      alignItems: "center",
      fontWeight: "bold",
      mr: [0, 4, 4],
      mb: "6px",
      textAlign: "start",
    }}
    {...props}
  />
)

export function NewsletterWrapper({ children, ...props }) {
  return (
    <Flex
      height="100%"
      width="100%"
      justifyContent="space-between"
      py="10px"
      px="16px"
      textAlign="center"
      sx={{
        backgroundColor: "blueMediumDark",
        bottom: 0,

        color: "white",
        ...props,
      }}
    >
      <WidthLimitation
        mx={[0, "auto"]}
        flexDirection={["column", "column", "row"]}
      >
        {children}
      </WidthLimitation>
    </Flex>
  )
}

export function MessageParagraph(props) {
  return (
    <Text
      sx={{
        fontFamily: "body",
        fontWeight: "body",
      }}
      {...props}
    />
  )
}

export function FooterHeading(props) {
  return (
    <Heading
      as="h4"
      sx={{
        fontHeight: 1,
        fontSize: 4,
        fontWeight: "body",
      }}
      {...props}
    />
  )
}

export function FooterSection({ children, name, ...props }) {
  return (
    <Box
      as="section"
      className="section"
      lineHeight={1.5625}
      sx={{ fontWeight: "light" }}
      {...props}
    >
      {name && (
        <Box mb={3}>
          <Text fontWeight="bold">{name}</Text>
        </Box>
      )}
      {children}
    </Box>
  )
}

export function FooterLineSection({ children }) {
  return (
    <Flex
      as="section"
      flexWrap="wrap"
      justifyContent="space-between"
      sx={{
        width: "100%",
        flexDirection: ["column", "column", "row"],
        "&:not(:last-child)": {
          mb: 3,
        },
        ".section": {
          flex: 1 / 4,
          "&:not(:last-child)": {
            mr: 5,
            mb: 4,
          },
        },
      }}
    >
      {children}
    </Flex>
  )
}

export function FooterExternalLink({ children, ...props }) {
  return (
    <Link as="a" sx={linkStyle} {...props}>
      {children}
    </Link>
  )
}

export function FooterLink({ children, sx, ...props }) {
  return (
    <GatsbyLink sx={{ ...linkStyle, ...sx }} {...props}>
      {children}
    </GatsbyLink>
  )
}

export function Address({ children, ...props }) {
  return (
    <Text sx={{ fontWeight: "light" }} {...props}>
      {children}
    </Text>
  )
}

export function SocialMediaWrapper(props) {
  return (
    <Flex
      sx={{
        display: "flex",
        justifyContent: ["center", "flex-start"],
        alignItems: "center",
        flexDirection: ["column", "row"],
        width: ["100%", "100%", "45%"],
      }}
      {...props}
    />
  )
}

export function SocialMediaItemsWrapper(props) {
  return (
    <ul
      sx={{
        listStyle: "none",
        marginTop: "20px",
        marginBottom: "20px",
        mx: [0, 0, "auto"],
        display: "flex",
      }}
      {...props}
    />
  )
}

export const FlagWrapper = ({ children, ...props }) => (
  <a
    sx={{
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: "white",
      mt: [4, 0],
    }}
    {...props}
  >
    {children}
  </a>
)

export const ButtonsWrapper = props => (
  <Flex
    sx={{
      flexDirection: ["column", "row"],
      marginY: "20px",
      marginX: [0, "auto"],
    }}
    {...props}
  />
)
