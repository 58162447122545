import React from "react"
import { Box, Flex } from "rebass"

export function Wrapper({ children, className }) {
  return (
    <Box
      sx={{
        ml: [0, 0, 5],
        flex: 1,
        "&.is-mobile": {
          display: "inline",
        },
        "&.show": {
          display: "inline",
        },
      }}
      className={className}
      display={["none", "none", "inline"]}
    >
      <Flex
        alignItems="center"
        as="nav"
        color="white"
        justifyContent="flex-end"
      >
        {children}
      </Flex>
    </Box>
  )
}

export const MenuItemsWrapper = ({ children }) => (
  <Box
    width="100%"
    as="ul"
    sx={{
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }}
  >
    {children}
  </Box>
)

export const MenuItem = ({ children, className }) => (
  <Box
    display={["block", "block", "inline"]}
    className={className}
    sx={{
      a: {
        background: "transparent",
        cursor: "pointer",
        color: "white",
        textDecoration: "none",
        fontWeight: "light",
        opacity: 0.5,
        "&.active": {
          fontWeight: "bold",
          opacity: 1,
        },
        "&:hover": {
          opacity: 0.9,
        },
      },
      "&:not(:first-of-type)": {
        mt: [3, 3, 0],
        ml: [0, 0, 3],
      },
      "&.hidden-in-desktop": {
        display: ["block", "block", "none"],
      },
    }}
    as="li"
  >
    {children}
  </Box>
)
