import React from "react"
import { Box } from "rebass"

export const Wrapper = props => (
  <Box
    sx={{
      transition: "opacity .6s linear, transform .6s linear",
      width: "100%",
      opacity: 0,
      height: "100vh",
      backgroundColor: "primary",
      position: "absolute",
      bottom: 0,
      paddingX: 3,
      paddingTop: 0,
      paddingBottom: 3,
      right: 0,
      transform: "translateY(-100%)",
      boxShadow: "card",

      "&.show": {
        opacity: 1,
        display: ["inline", "inline", "none"],
        transform: `translateY(100%)`,
      },
    }}
    {...props}
  />
)
