import React from "react"
import { Box, Flex, Text } from "rebass"
import { X } from "react-feather"

import Picture1 from "@images/exitIntent/exit-intent-staff-1.svg"
import Picture2 from "@images/exitIntent/exit-intent-staff-2.svg"
import Picture3 from "@images/exitIntent/exit-intent-staff-3.svg"
import Picture4 from "@images/exitIntent/exit-intent-staff-4.svg"
import Picture5 from "@images/exitIntent/exit-intent-staff-5.svg"
import { Botao } from "@xapps/design-system"

const pictures = [
  <Picture1 />,
  <Picture2 />,
  <Picture3 />,
  <Picture4 />,
  <Picture5 />,
];

export const Wrapper = props => (
  <Flex
    sx={{
      px: 4,
      pb: 4,
      pt: [5, 4],
    }}
    {...props}
  />
)

export const SeeMoreButton = props => (
  <Botao variant="apoio" style={{ borderRadius: 50 }} {...props} />
)

export const SubmitButton = props => (
  <Botao
    variant="apoio"
    style={{ borderRadius: 50 }}
    sx={{ width: "100%" }}
    {...props}
  />
)

export const FormWrapper = props => (
  <Box
    sx={{
      width: "100%",
    }}
    {...props}
  />
)

export const XIcon = props => (
  <Flex
    sx={{
      position: "absolute",
      top: "16px",
      right: "18px",
      cursor: "pointer",
    }}
    {...props}
  >
    <X />
  </Flex>
)

export const Label = props => (
  <Text
    sx={{
      fontWeight: "bold",
      fontSize: "26px",
      marginBottom: "8px",
      color: "lightBlack",
    }}
    {...props}
  />
)

export const Description = props => (
  <Text sx={{ marginBottom: 24, color: "lightBlack" }} {...props} />
)

export const DataFormWrapper = props => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: ["100%", "317px"],
      marginLeft: [0, 52],
    }}
    {...props}
  />
)

export const StaffPicture = props => {
  return (
    <Box
      sx={{
        position: ["absolute", "relative"],
        left: ["25%", 0],
        top: ["-75px", 0],
        svg: {
          width: [150, 268],
          height: [137, 246],
        }
      }}
      {...props}
    >
      {pictures[props.index]}
      {/* <Picture /> */}
    </Box>
  );
}
