import FacebookIcon from "@images/socialMedia/facebook.svg"
import InstagramIcon from "@images/socialMedia/instagram.svg"
import LinkedinIcon from "@images/socialMedia/linkedin.svg"
import TwitterIcon from "@images/socialMedia/twitter.svg"
import WhatsAppIcon from "@images/socialMedia/whatsapp.svg"
import YoutubeIcon from "@images/socialMedia/youtube.svg"
import AgileProjectsIcon from "@images/svg/agileProjects.svg"
import DevopsOperationIcon from "@images/svg/devopsOperation.svg"
import OnDemandIcon from "@images/svg/onDemand.svg"
import get from "lodash/get"
import React from "react"

export const icons = [
  <FacebookIcon />,
  <InstagramIcon />,
  <LinkedinIcon />,
  <TwitterIcon />,
  <YoutubeIcon />,
  <WhatsAppIcon />,
]

const otherIcons = {
  agileProjects: <AgileProjectsIcon />,
  devops: <DevopsOperationIcon />,
  developerOnDemand: <OnDemandIcon />,
  facebook: <FacebookIcon />,
  instagram: <InstagramIcon />,
  linkedin: <LinkedinIcon />,
  twitter: <TwitterIcon />,
  youtube: <YoutubeIcon />,
  whatsapp: <WhatsAppIcon />,
}

function ImagesSVG({ svg }) {
  return get(otherIcons, svg, null)
}

export default ImagesSVG
