import React, { useState } from "react"
import Translate from "@components/Translate"
import { useTranslate } from "@utils/hooks/useTranslate"
import { Box } from "rebass"
import { useAskForBudget } from "@utils/hooks/useAskForBudget"
import Fade from "react-reveal/Fade"
import * as Style from "./ExitIntent.styles"
import { InputTelefone, Input } from "@xapps/design-system"

function ExitIntent() {
  const [visibleForm, setVisibleForm] = React.useState(false)
  const { handleSubmit, handleChange, values, isSubmitting } = useAskForBudget()
  const [imageIndex] = useState(randomIntFromInterval(0, 4));

  const formatMessage = useTranslate()

  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  return (
    <Style.Wrapper>
      <Fade>
        <Style.StaffPicture index={imageIndex} />
        <Style.DataFormWrapper>
          <Box>
            <Style.Label>
              <Translate id="exitIntentPopup.title" />
            </Style.Label>
            <Style.Description>
              <Translate
                id={
                  visibleForm
                    ? "exitIntentPopup.description"
                    : "exitIntentPopup.subtitle"
                }
              />
            </Style.Description>
          </Box>
          {!visibleForm && (
            <Style.SeeMoreButton onClick={() => setVisibleForm(true)}>
              <Translate id="exitIntentPopup.seeMoreButton" />
            </Style.SeeMoreButton>
          )}
          {visibleForm && (
            <Style.FormWrapper as="form" onSubmit={handleSubmit}>
              <Input
                id="firstName"
                name="firstName"
                containerProps={{ marginBottom: 24 }}
                required
                value={values.firstName}
                rotulo={formatMessage("exitIntentPopup.formFirstName")}
                onChange={handleChange}
              />

              <Input
                name="lastName"
                containerProps={{ marginBottom: 24 }}
                required
                value={values.lastName}
                rotulo={formatMessage("exitIntentPopup.formLastName")}
                onChange={handleChange}
              />

              <Input
                name="email"
                type="email"
                containerProps={{ marginBottom: 24 }}
                required
                value={values.email}
                rotulo={formatMessage("exitIntentPopup.formEmail")}
                onChange={handleChange}
              />

              <InputTelefone
                nome="phone"
                required
                international
                containerProps={{ marginBottom: 24 }}
                lingua={process.env.GATSBY_LANGUAGE}
                defaultCountry={process.env.GATSBY_LANGUAGE === "en" ? "US" : "BR"}
                onChange={handleChange}
                value={values.phone}
                rotulo={formatMessage("exitIntentPopup.formTelefone")}
              />

              <Style.SubmitButton type="submit" desabilitado={isSubmitting}>
                <Translate id="exitIntentPopup.SubmitForm" />
              </Style.SubmitButton>
            </Style.FormWrapper>
          )}
        </Style.DataFormWrapper>
      </Fade>
    </Style.Wrapper>
  )
}

export default ExitIntent
