import Translate from "@components/Translate"
import React, { useEffect } from "react"
import { toast } from "react-toastify"

const VERSION_KEY = `${process.env.GATSBY_VERSION}-wasNotified`

export function useNewVersionAlert() {
  useEffect(() => {
    function handleNewVersion() {
      if (!localStorage.getItem(VERSION_KEY))
        toast.info(<Translate id="newVersionAvailable" />, {
          onOpen: () => localStorage.setItem(VERSION_KEY, true),
          onClick: () => window.location.reload(true),
        })
    }
    window.addEventListener("newVersion", handleNewVersion)
    return () => window.removeEventListener("newVersion", handleNewVersion)
  }, [])
}
