import React from "react"
import { Box } from "rebass"

export const ListItem = props => (
  <Box
    as="li"
    sx={{
      width: "20px",
      marginRight: 3,
      "&:last-of-type": {
        marginRight: 0,
      },
    }}
    {...props}
  />
)
export const IconWrapper = props => (
  <Box
    sx={{
      "& svg": {
        display: "inline",
        cursor: "pointer",
        fill: "white",
        color: "white",
        path: {
          fill: "currentColor !important",
        },
        "&:hover": {
          transition: "0.2s opacity",
          opacity: 0.4,
        },
      },
    }}
    {...props}
  />
)
