import ImagesSVG from "@components/ImagesSVG"
import Logo from "@components/Logo"
import Translate, { TranslateHTML } from "@components/Translate"
import SocialMedia from "@constants/socialMedia"
import LogoGartner from "@images/gartner.svg"
import routes from "@utils/routes"
import { Link } from "gatsby-plugin-intl"
import React from "react"
import { Box, Flex } from "rebass"
import SiteMap from "../SiteMap"
import * as Style from "./Footer.styles"
import { SocialMediaItem } from "./SocialMediaItem"
import { Newsletter } from "./Newsletter"
import { flags } from "./Flags"
import { Botao } from "@xapps/design-system"
import { IdsDosBotoesDeConversaoDoLeadsCenter } from "@constants/forms"

export default function Footer({ hideFooter, hideFooterRequestBudget }) {
  if (hideFooter) {
    return null
  }
  return (
    <footer>
      {!hideFooterRequestBudget && (
        <Style.MessageWrapper backgroundColor="blue">
          <Style.FooterHeading>
            <Translate id="footer.accelerateBusinessWithXApps" />
          </Style.FooterHeading>
          <Style.ButtonsWrapper>
            {process.env.GATSBY_LANGUAGE !== "en" && (
              <Botao
                sx={{ marginRight: [0, 3], marginBottom: [3, 0], py: 3 }}
                variant="terciario"
                as={Link}
                // href="https://perfil.x-apps.com.br/"
                href={routes.contrateSquads}
              >
                <Translate id="header.buttons.hireSquad2" />
              </Botao>
            )}
            <Botao
              py={3}
              as={Link}
              to={`${routes.contato}?form_id=${IdsDosBotoesDeConversaoDoLeadsCenter.footer}`}
            >
              <Translate id="header.buttons.requestBudget" />
            </Botao>
          </Style.ButtonsWrapper>
        </Style.MessageWrapper>
      )}

      <Style.MessageWrapper backgroundColor="blueMedium">
        <Style.MessageParagraph>
          <Translate id="footer.XAppsIsAnITProvider" />
        </Style.MessageParagraph>

        <Flex
          sx={{
            justifyContent: "center",
          }}
          mt={3}
        >
          <LogoGartner />
        </Flex>
      </Style.MessageWrapper>

      <Style.NewsletterWrapper>
        <Newsletter />
        <Style.VerticalDivider />
        <Style.SocialMediaWrapper>
          <Style.SocialMediaTitle>
            <Translate id="footer.socialMediaTitle" />
          </Style.SocialMediaTitle>
          <Style.SocialMediaItemsWrapper>
            {SocialMedia.map((media, index) => (
              <SocialMediaItem
                key={index}
                link={media.link}
                icon={<ImagesSVG svg={media.icon} />}
              />
            ))}
          </Style.SocialMediaItemsWrapper>
        </Style.SocialMediaWrapper>
      </Style.NewsletterWrapper>

      <Style.Wrapper>
        <Style.ColumnWrapper>
          <Style.FooterLineSection>
            <Style.FooterSection name={<Logo width="122px" />}>
              <Translate id="footer.yourITTeam" mt="2" />
            </Style.FooterSection>
            <Style.FooterSection
              as="address"
              name={<Translate id="footer.sections.letsTalk.title" />}
            >
              <Style.FooterExternalLink href="mailto:comercial@x-apps.com.br">
                comercial@x-apps.com.br
              </Style.FooterExternalLink>
              <Style.FooterExternalLink href="tel:+551150830122">
                <Translate id="footer.sections.letsTalk.phone" />
              </Style.FooterExternalLink>
              <Style.Address mt={3}>
                <TranslateHTML id="footer.sections.letsTalk.address" />
              </Style.Address>
            </Style.FooterSection>
            <Style.FooterSection
              name={<Translate id="footer.sections.siteMap" />}
            >
              <SiteMap />
            </Style.FooterSection>
          </Style.FooterLineSection>
        </Style.ColumnWrapper>
        <Style.ColumnWrapper hasBorder>
          <Flex>
            <Style.FooterLink
              sx={{
                mr: 4,
              }}
              to={routes.termosDeServico}
            >
              <Translate id="footer.termsOfService" />
            </Style.FooterLink>

            <Style.FooterLink to={routes.termosDePrivacidade}>
              <Translate id="footer.privacyPolicy" />
            </Style.FooterLink>
          </Flex>
          <Style.FlagWrapper href={flags.href}>
            <Box sx={{ mr: 3 }}>
              <Translate id="footer.switchSites" />
            </Box>
            {flags.icon}
          </Style.FlagWrapper>
        </Style.ColumnWrapper>
      </Style.Wrapper>
    </footer>
  )
}
