import { Botao } from "@xapps/design-system"
import React from "react"
import { Flex, Text } from "rebass"

export const Wrapper = props => (
  <Flex
    as="div"
    sx={{
      justifyContent: "flex-start",
      flexDirection: ["column", "row"],
      alignItems: "center",
      width: "100%",
      py: "30px",
    }}
    {...props}
  />
)

export const Title = props => (
  <Text
    sx={{ fontWeight: "bold", mr: [0, 3], textAlign: "start", mb: [2, 0] }}
    {...props}
  />
)

export const SubmitButton = props => (
  <Botao
    variant="apoio"
    style={{ borderRadius: 50 }}
    sx={{ mt: [3, 0], minWidth: ["100%", "fit-content"] }}
    {...props}
  />
)
