export default [
  { link: "https://www.facebook.com/xappsbr/", icon: "facebook" },
  { link: "https://www.instagram.com/xapps_", icon: "instagram" },
  { link: "https://www.linkedin.com/company/x-apps/", icon: "linkedin" },
  { link: "https://twitter.com/xappsbr", icon: "twitter" },
  {
    link: "https://www.youtube.com/channel/UCH7aljDfu-XyqE7vazyYaaw",
    icon: "youtube",
  },
]
