import Translate from "@components/Translate"
import { identifyUser } from "@utils/identify"
import { sendFormToMautic } from "@utils/mautic"
import { useFormik } from "formik"
import React from "react"
import { toast } from "react-toastify"

const NEWSLETTER_MAUTIC_FORM_ID = 13

export function useSubscribeToNewsletterForm(email = "") {
  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    resetForm,
  } = useFormik({
    onSubmit: sendToMautic,
    initialValues: {
      email,
    },
  })

  function sendToMautic(data) {
    const newData = {
      "mauticform[email]": data.email,
      "mauticform[formName]": "newsletter",
      "mauticform[formId]": NEWSLETTER_MAUTIC_FORM_ID,
    }
    identifyUser(data.email)
    sendFormToMautic(NEWSLETTER_MAUTIC_FORM_ID, newData).then(() => {
      toast.success(<Translate id="newsletter.successfulSubscribed" />)
      resetForm()
    })
  }

  return {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    resetForm,
  }
}
