import ZIndexLayers from "@utils/ZIndexLayers"
import React, { forwardRef } from "react"
import { Box, Flex } from "rebass"

export const MENU_HEIGHT = [66, 66, 84]

export const Wrapper = forwardRef((props, ref) => (
  <Flex
    ref={ref}
    as="header"
    backgroundColor="blue"
    alignItems="center"
    justifyContent="center"
    flexDirection="row"
    width="100vw"
    padding={3}
    sx={{
      position: "fixed",
      top: 0,
      height: MENU_HEIGHT,
      zIndex: ZIndexLayers.TOP,
      ".menu-icon": {
        display: ["inline", "inline", "none"],
        color: "white",
      },
    }}
    {...props}
  />
))

export const ButtonWrapper = ({ children }) => (
  <Box
    sx={{
      justifyContent: "flex-end",
      alignItems: "center",
      display: ["none", "none", "flex"],
    }}
  >
    {children}
  </Box>
)
