import { menuLabel } from "@components/Menu/menuLabel"
import { menuItems } from "@constants/home"
import { Link } from "gatsby-plugin-intl"
import React from "react"
import { Box } from "rebass"

function SiteMap() {
  return (
    <nav>
      <Box
        as="ul"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          listStyle: "none",
          maxHeight: "150px",
          "& li:not(:last-child)": {
            mb: 3,
          },
          a: {
            color: "white",
            fontWeight: "200",
            textDecoration: "none",
            "&.active": {
              fontWeight: "bold",
            },
            "&:hover": {
              opacity: 0.8,
            },
          },
        }}
      >
        {menuItems.map((item, index) => (
          <li key={index}>
            <Link activeClassName="active" to={item}>
              {menuLabel[item]}
            </Link>
          </li>
        ))}
      </Box>
    </nav>
  )
}

export default SiteMap
