import React from 'react'
import usFlag from "@images/svg/unitedStatesFlag.svg"
import brazilFlag from "@images/svg/brazilFlag.svg"

export const flags = {
  en: {
    icon: React.createElement(brazilFlag),
    href: "https://www.x-apps.com.br/"
  },
  "pt-BR": {
    icon: React.createElement(usFlag),
    href: "https://www.x-apps.dev/"
  },
}[process.env.GATSBY_LANGUAGE]
