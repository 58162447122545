import React from "react"
import { Input } from "@rebass/forms"
import Translate from "@components/Translate"
import { useTranslate } from "@utils/hooks/useTranslate"
import { useSubscribeToNewsletterForm } from "@utils/hooks/useSubscribeToNewsletterForm"
import * as Style from "./Newsletter.styles"

export function Newsletter() {
  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
  } = useSubscribeToNewsletterForm()
  const translateMessage = useTranslate()
  return (
    <Style.Wrapper as="form" onSubmit={handleSubmit}>
      <Style.Title>
        <Translate id="footer.newsletter" />
      </Style.Title>
      <Input
        variant="baseColorInput"
        type="email"
        required
        name="email"
        mr={[0, "16px"]}
        maxWidth={["unset", "360px"]}
        onChange={handleChange}
        value={values.email}
        placeholder={translateMessage("footer.emailPlaceholder")}
      />
      <Style.SubmitButton disabled={isSubmitting} type="submit">
        <Translate id="footer.submitLabel" />
      </Style.SubmitButton>
    </Style.Wrapper>
  )
}
