import "@styles/reset.css"
import { useNewVersionAlert } from "@utils/hooks/useNewVersionAlert"
import { useOnExitIntent } from "@utils/hooks/useOnExitIntent"
import { useModal } from "@xapps/design-system"
import { useStoreCampaignVariablesInCookies } from "@utils/hooks/useStoreCampaignVariablesInCookies"
import React, { useEffect } from "react"
import { ToastContainer } from "react-toastify"
import * as Styles from "./BaseLayout.styles"
import Footer from "./Footer"
import Header from "./Header"
import ExitIntent from "@components/ExitIntent"
import { manageCookies } from "../../utils/manageCookies"

function BaseLayout({
  children,
  hero,
  hideFooter,
  hideMenu,
  hideHeaderButtons,
  hideFooterRequestBudget,
  isDark,
  underMenuItem,
}) {
  const [modalElement, showIntentModal] = useModal(<ExitIntent />)
  useOnExitIntent(() => {
    manageCookies.setExpiresExitIntentCookies();
    showIntentModal();
  })
  useStoreCampaignVariablesInCookies()
  useNewVersionAlert()


  useEffect(() => {
    if (window.location.hostname === "x-apps.com.br") window.location.host = "www.x-apps.com.br"
  }, [])

  return (
    <Styles.Wrapper isDark={isDark}>
      <ToastContainer />
      {modalElement}
      <Header
        underMenuItem={underMenuItem}
        hideMenu={hideMenu}
        hideHeaderButtons={hideHeaderButtons}
      />
      <Styles.ContentWrapper>
        {hero}
        {children}
      </Styles.ContentWrapper>
      <Footer
        hideFooter={hideFooter}
        hideFooterRequestBudget={hideFooterRequestBudget}
      />
    </Styles.Wrapper>
  )
}

export default BaseLayout
